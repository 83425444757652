import { Api } from '@/libraries/api'

async function getItems() {
  const url = `/admin/pass`
  const result = await Api().get(url)
  const { items } = result.data.data
  return items
}

async function setItems(items) {
  const url = `/admin/pass`
  const data = {
    service_pass_info: items
  }
  const res = await Api().post(url, data)
  const { result } = res.data
  return result
}

export default {
  getItems,
  setItems
}
