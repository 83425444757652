<template>
  <v-form
    ref="form"
    v-model="valid"
    :lazy-validation="lazy"
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <PageTitleComponent title="결제요금 관리" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          cols="12"
          sm="8"
        >
          <v-container fluid>
            <v-row v-show="true">
              <v-col class="pt-8">
                <InformationComponent
                  type="info"
                  :messages="[`* 요금정보 수정 시, google, apple 각각 앱 요금 설정 정보도 함께 변경되어야 합니다.`]"
                />
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col
                cols="12"
                class="pt-4 text-h6"
              >
                요금정보
              </v-col>
            </v-row> -->
            <v-row align="center">
              <v-col
                cols="4"
                class="text-center"
              >
                1개월
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="payments.one_month.android_cost"
                  label="Android 요금"
                  :rules="[rules.required]"
                  type="number"
                  hide-details="auto"
                />
              </v-col>
              <v-col
                cols="4"
                class="text-center"
              >
                <v-text-field
                  v-model="payments.one_month.ios_cost"
                  label="iOS 요금"
                  :rules="[rules.required]"
                  type="number"
                  hide-details="auto"
                />
              </v-col>
              <v-col
                cols="4"
                class="text-center"
              >
                3개월
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="payments.three_month.android_cost"
                  label="Android 요금"
                  :rules="[rules.required]"
                  type="number"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="payments.three_month.ios_cost"
                  label="iOS 요금"
                  :rules="[rules.required]"
                  type="number"
                  hide-details="auto"
                />
              </v-col>
              <v-col
                cols="4"
                class="text-center"
              >
                6개월
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="payments.six_month.android_cost"
                  label="Android 요금"
                  :rules="[rules.required]"
                  type="number"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="payments.six_month.ios_cost"
                  label="iOS 요금"
                  :rules="[rules.required]"
                  type="number"
                  hide-details="auto"
                />
              </v-col>
              <v-col
                cols="4"
                class="text-center"
              >
                1년
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="payments.one_year.android_cost"
                  label="Android 요금"
                  :rules="[rules.required]"
                  type="number"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="payments.one_year.ios_cost"
                  label="iOS 요금"
                  :rules="[rules.required]"
                  type="number"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col
                cols="12"
                class="pt-16 text-h6"
              >
                계좌정보
              </v-col>
              <v-col
                cols="4"
                class="text-center"
              >
                <v-text-field
                  v-model="accounts.bank1.name"
                  label="은행"
                  :rules="[rules.required]"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="accounts.bank1.account"
                  label="계좌"
                  :rules="[rules.required]"
                  hide-details="auto"
                />
              </v-col>
              <v-col
                cols="4"
                class="text-center"
              >
                <v-text-field
                  v-model="accounts.bank2.name"
                  label="은행"
                  :rules="[rules.required]"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="accounts.bank2.account"
                  label="계좌"
                  :rules="[rules.required]"
                  hide-details="auto"
                />
              </v-col>
              <v-col
                cols="4"
                class="text-center"
              >
                <v-text-field
                  v-model="accounts.bank3.name"
                  label="은행"
                  :rules="[rules.required]"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="accounts.bank3.account"
                  label="계좌"
                  :rules="[rules.required]"
                  hide-details="auto"
                />
              </v-col>
            </v-row> -->

            <v-row
              justify="center"
              class="mt-16"
            >
              <v-col
                cols="auto"
              >
                <v-btn
                  class="mx-4"
                  color="primary"
                  @click="updateItem"
                >
                  <v-icon small>
                    edit
                  </v-icon>
                  <span class="pl-2">수정</span>
                </v-btn>
                <v-btn
                  color=""
                  class="mx-4"
                  @click="$router.go(-1)"
                >
                  <v-icon small>
                    arrow_back
                  </v-icon>
                  <span class="pl-2">이전 페이지로</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Config from '../../config'
import PageTitleComponent from '../_elements/title/PageTitle'
import InformationComponent from '../_elements/textbox/Information'
import Api from './api/paymentsApi'

export default {
  components: {
    PageTitleComponent,
    InformationComponent
  },
  data() {
    return {
      config: Config,
      lazy: false,
      valid: false,
      payments: {
        one_month: {
          period: 1,
          ios_cost: 35000,
          android_cost: 35000,
          product_id: "com.dspirit.helpers.pass1"
        },
        three_month: {
          period: 3,
          ios_cost: 105000,
          android_cost: 105000,
          product_id: "com.dspirit.helpers.pass2"
        },
        six_month: {
          period: 6,
          ios_cost: 179000,
          android_cost: 175000,
          product_id: "com.dspirit.helpers.pass3"
        },
        one_year: {
          period: 12,
          ios_cost: 369000,
          android_cost: 350000,
          product_id: "com.dspirit.helpers.pass4"
        },
        custom: {
          period: -1,
          ios_cost: 0,
          android_cost: 0,
          product_id: "admin"
        }
      },
      accounts: {
        bank1: {
          name: '국민은행',
          account: '031601-04-226733'
        },
        bank2: {
          name: '케이뱅크',
          account: '700-171-660001'
        },
        bank3: {
          name: '농협',
          account: '351-1191-1699-93'
        }
      },
      rules: {
        required: (value) => !!value || '꼭 입력해주세요'
      }
    }
  },
  async created() {
    await this.setup()
  },
  methods: {
    async setup() {
      await this.getItems()
    },
    async getItems() {
      const items = await Api.getItems()
      items.forEach((item) => {
        const itemConv = item
        delete itemConv.idx
        if (item.period === 1) {
          this.payments.one_month = itemConv
        } else if (item.period === 3) {
          this.payments.three_month = itemConv
        } else if (item.period === 6) {
          this.payments.six_month = itemConv
        } else if (item.period === 12) {
          this.payments.one_year = itemConv
        } else if (item.period === -1) {
          this.payments.custom = itemConv
        }
      })
    },
    async updateItem() {
      const items = [
        this.payments.one_month,
        this.payments.three_month,
        this.payments.six_month,
        this.payments.one_year,
        this.payments.custom
      ]
      const result = await Api.setItems(items)
      if (result === true) {
        this.$bus.$emit('successMessage', '수정되었습니다')
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
